@import url('https://fonts.googleapis.com/css2?family=Rajdhani&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredericka+the+Great&display=swap');



/* G e n e r a l   s t y l e s */

@media (min-width:1280px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl .wide {
        max-width: 1220px !important;
    }
}

h4 {
    font-family: 'Rajdhani', sans-serif;
    text-transform: uppercase;
    color: black;
}

.color-950707 {
    color: #950707;
}

.margin-auto {
    margin: auto 0;
}

//.nav-border-right {
//    border-right: 1px solid #999;
//}


.border-left-right {
    border-left: 1px solid #E5E5E5;
    border-right: 1px solid #E5E5E5;
}

.category-post-link {
    color: #3f4042;
}

.category-post-link:hover {
    color: #950707;
}

/* h e a d e r */

.page-title {
    font-family: Georgia, 'Times New Roman', Times, serif;
    text-align: left;
}

.page-title a {
    text-decoration: none;
    color: #ff7e00;
    font-weight: bold;
    font-size: 1.5rem;
}

.page-title a:hover {
    text-decoration: none;
    color: #3f4042;
}

.inftechall {
    margin-left: -1px;
    font-size: 1.4rem;
    display: inline;
    color: #3f4042;
    font-weight: bold;
}

.pl {
    font-size: 1.2rem;
    display: inline;
    color: #3f4042;
    font-weight: normal;
}

@media (min-width:500px) {
    .page-title a {
        font-size: 5rem;
    }

    .inftechall {
        font-size: 4rem;
        margin-left: -15px;
    }

    .pl {
        font-size: 3.5rem;
    }
}

/* n a v i g a t i o n */

.category-navigation {
    border-top: 1px solid rgb(112, 112, 112);
    border-bottom: 1px solid rgb(112, 112, 112);
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold;
}

nav {
    font-family: 'Rajdhani', sans-serif;
    font-size: 1.1rem;
    letter-spacing: 0.2rem;
}

nav a {
    color: black;
}

nav a:hover {
    color: #950707;
}

.justify-content-between {
    width: 100%;
    justify-content: space-between !important;
    padding-left: 0;
}

.justify-content-between a {
	padding-right: 5px;
	padding-left: 5px;
}

/* p o p u l a r  -  p o s t */

.popular-post-row {
    margin-right: 0px !important;
}

.popular-post-padding {
    padding-right: 0px !important;
}

.popular-post-h4-border {
    border: 1px solid rgb(112, 112, 112);
    padding: 3px 6px 3px 6px;
    text-align: center;
    letter-spacing: 2px;
    background: #ff7e00;
    color: white;
    font-weight: bold;
}

.popular-post-line {
    border-top: 1px solid rgb(112, 112, 112);
    margin-top: 18px;
}

.popular-post {
    padding: 10px;
    border-bottom: 1px solid rgb(112, 112, 112);
}

.popular-post-content {
    margin: 10px;
    font-family: 'Rajdhani';
    font-size: 1.1rem;
}

.popular-post-title a {
    color: black;
}

.continue-reding a {
    color: black;
}

.continue-reding a:hover {
    color: #950707;
}

/* m a i n  s e c t i o n */
.col-md-3 {
    margin-right: -20px;
}

main {
    font-family: 'Rajdhani';
    font-size: 1.2rem;
    min-height: 60vh;
}

h2 a {
    color: black;
    font-size: 1.6rem;
}

h2 a:hover {
    color: #950707;
    text-decoration: none;
}

.row-post {
    padding-top: 10px;
}

.post {
    border-bottom: 1px solid #E5E5E5;
    padding-bottom: 1.2rem;
    padding-top: 1.2rem;
}

.img-thumbnail {
    border: 1px solid black !important;
    border-radius: 0px !important;
    padding: 0px !important;
}

.post-meta {
    display: inline;
    padding-right: 12px;
    font-size: 1rem;
}

.post-content {
    margin-top: 10px;
    margin-bottom: -14px;
}

.post-thumbnail-size {
    width: 667px;
    height: 337px;
}

.read-more {
    color: #777;
    text-decoration: underline;
    font-size: 1rem;
    letter-spacing: 0.1rem;
}

.read-more:hover {
    color: #950707;
    text-decoration: underline;
    font-size: 1rem;
    letter-spacing: 0.1rem;
}

.tags-post {
    color: #000;
    font-size: 0.9rem;
}

.tags-post:hover {
    color: #950707;
}

ul.tags-list {
    list-style-type: square;
}

/* F O O T E R */

.footer {
    border-top: 1px solid #999;
    background-color: #f9f9f9;
    padding: 10px;
    margin-top: 22px;
    text-align: center;
}

.footer-credits {
    font-family: 'Rajdhani', sans-serif;
    letter-spacing: 3px;
    color: #777;
}

.footer-back-to-top {
    font-family: 'Rajdhani', sans-serif;
    letter-spacing: 3px;
}

.footer-navigation {
    padding: 10px 0 10px 0;
}

.footer-navigation a {
    padding-right: 15px;
    padding-left: 15px;
}

/* W I D G E T */
/* T A G S*/
.widget {
    border-left: 1px solid #E5E5E5;
}

.widget-title {
    padding-top: 20px;
    letter-spacing: 6px;
    font-size: 1.1rem;
}

.tag {
    margin: 2px 0px 2px 0px;
    padding: 0px 2px 0px 2px;
    cursor: pointer;
    border: 1px solid #e5e5e5;
    color: rgb(63, 63, 63);
    display: inline;
}

a:hover .tag {
    font-weight: 600;
    color: black;
}

/* --- */

/* link to another page on inftechall.pl */
.reference {
    border: 1px solid rgb(146, 146, 146);
    padding: 12px;
}

/* PAGINATION */

.pagination {
    font-size: 1rem;
}

.page_number,
.previous,
.pages,
.next {
    text-decoration: none;
    border: 1px solid #b8b8b8;
    padding: 2px 4px;
    margin: 2px;
    color: #000;
}

.previous:hover,
.pages:hover,
.next:hover {
    color: #950707;
    scale: 120%;
}

.current-page {
    color: #950707;
    scale: 115%;
}

/* C O D E */

code {
    color: #ae0000;
}

.highlighter-rouge {
    color: #ae0000;
}

.pre-code {
    display: block;
    font-size: 0.85rem;
    color: #000;
    border: 1px solid #5e84aa;
    padding: 10px 10px 10px 10px;
}

.pre-code-comment {
    color: #9b0000;
    font-style: italic;
    font-size: 0.8rem;
}

.command-option {
    color: #2d2c2c;
    font-size: 1rem;
}