/* C o o k i e s */

#cookie {
    display: block;
    z-index: 99999;
    min-height: 35px;
    width: 100%;
    position: fixed;
    background: rgb(43, 54, 67) none repeat scroll 0% 0%;
    border-image: none 100% / 1 / 0 stretch;
    border-top: 1px solid rgb(160, 178, 192);
    text-align: center;
    right: 0px;
    color: rgb(119, 119, 119);
    left: 0px;
    border-color: rgb(160, 178, 192);
    border-radius: 0px;
}

#cookie-test {
    padding: 10px;
    margin-left: 15px;
    margin-right: 15px;
    font-size: 14px;
    font-weight: normal;
}

.cookie-more {
    color: #0056b3;
    letter-spacing: 2px;
}

.cookie-more:hover {
    text-decoration: underline;
}

#cookie-btn-ok {
    letter-spacing: 2px;
    position: absolute;
    background: #2577b7 none repeat scroll 0% 0%;
    color: rgb(255, 255, 255);
    padding: 5px 15px;
    text-decoration: none;
    font-size: 12px;
    font-weight: normal;
    border: 0px solid rgb(43, 54, 67);
    border-radius: 0px;
    top: 5px;
    right: 5px;
}

#cookie-btn-ok:hover {
    text-decoration: underline;
    font-weight: bold;
}